import Vue from 'vue'
import VueRouter from 'vue-router'
import PortfolioView from '@/views/PortfolioView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'portfolio',
    component: PortfolioView
  },
  {
    path: '/stock/:ticker',
    name: 'stock',
    component: () => import(/* webpackChunkName: "stock" */ '../views/StockView.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/SettingsView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
