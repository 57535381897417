<template lang="pug">
v-navigation-drawer(
    v-model="drawerOpen"
    absolute
    temporary
)
    v-list(nav)
        //- v-model="group"
        v-list-item-group(
            active-class="blue-grey--text text--accent-4"
        )
            router-link(
                v-for="menuItem in menuItems"
                :key="menuItem.name"
                :to="{name: menuItem.name}"
                style="text-decoration: none; color: inherit;"
            )
                v-list-item
                    v-list-item-icon
                        v-icon {{ menuItem.icon }}
                    v-list-item-title {{ menuItem.title }}

</template>

<script>

export default {
    name: 'NavigationDrawer',
    props: {
        value: Boolean,
    },
    compomnents: {
        // NavigationDrawer,
    },
    computed: {
        drawerOpen: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        menuItems() {
            return [
                {
                    name: 'portfolio',
                    icon: 'mdi-home',
                    title: 'My Portfolio',
                },
                {
                    name: 'settings',
                    icon: 'mdi-cog',
                    title: 'Settings',
                },
            ]
        }
    },
}

</script>
