<template lang="pug">
div
    h2.ma-4 My portfolio
    .d-flex
        v-text-field.mx-3(
            v-model="filter"
            label="Filter"
            prepend-icon="mdi-magnify"
            dense
            outlined
            clearable
        )
        v-btn.mr-3(
            icon
            :to="{name: 'settings'}"
        )
            v-icon(
                    color="primary"
                ) mdi-cog
    //- div {{ headers }}
    v-data-table.mx-3(
        :headers="headers"
        :items="stocks"
        item-key="ticker"
        sort-by="ticker"
        class="elevation-2"
        fixed-header
        @click:row="rowClicked"
    )
        //- template(v-slot:item.actions="{ item }")
            v-btn(
                :to="{name: 'stock', params: {ticker: item.ticker}}"
                small
                text
                elevation="2"
            ) Details
</template>

<script>
import { mapState } from 'pinia'
import { useStore } from '@/stores/portfolio'

export default {
    name: 'PortfolioView',
    components: {
    },
    computed: {
        ...mapState(useStore, [
            'all_stocks',
            'portfolio_columns',
        ]),
        stocks() {
            return this.all_stocks.filter(stock => {
                return (this.filterInfix(stock.ticker) || this.filterInfix(stock.name))
            })
        },
        headers() {
            return [
                { text: 'Ticker', align: 'start', value: 'ticker' },
                ...this.portfolio_columns.map(column => {
                    return {
                        text: column.label,
                        value: column.name,
                        align: column.name === 'name' ? 'start' : 'right',
                    }
                })
            ]
        }
    },
    methods: {
        filterInfix(attr) {
            return this.filter === null || attr.toLowerCase().indexOf(this.filter.toLowerCase()) !== -1
        },
        rowClicked(item) {
            this.$router.push({name: 'stock', params: {ticker: item.ticker}})
        }
    },
    data: () => ({
        filter: '',
    }),
}
</script>
