<template lang="pug">
v-app
    AppBar(@openDrawer="drawer = true")
    NavigationDrawer(v-model="drawer")
    v-main
        router-view
</template>

<script>

import AppBar from '@/components/AppBar'
import NavigationDrawer from '@/components/NavigationDrawer'

export default {
    name: 'App',
    components: {
        AppBar,
        NavigationDrawer,
    },
    data: () => ({
        drawer: false,
    }),
    metaInfo: {
        title: 'MyStockPortfolio',
    },
};
</script>
