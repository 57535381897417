import { defineStore } from 'pinia'

export const useStore = defineStore('portfolio', {
    state: () => {
        return {
            all_stocks: [
                {
                    ticker: 'AAPL',
                    name: 'Apple',
                    price: '145.38',
                    pe: '23.59',
                    dividendYield: '0.61%',
                },
                {
                    ticker: 'BABA',
                    name: 'Alibaba Group Holding',
                    price: '93.21',
                    pe: '27.31',
                    dividendYield: '-',
                },
                {
                    ticker: 'PM',
                    name: 'Philip Morris International',
                    price: '105.52',
                    pe: '18.26',
                    dividendYield: '4.71%',
                },
            ],
            default_portfolio_columns: [
                {
                    name: 'name',
                    label: 'Company name',
                },
                {
                    name: 'price',
                    label: 'Price',
                },
                {
                    name: 'pe',
                    label: 'P/E',
                },
                {
                    name: 'dividendYield',
                    label: 'Dividend yield',
                },
            ],
        }
    },
    getters: {
        portfolio_columns() {
            const names = localStorage.getItem('portfolio_columns')
            if (names) {
                return JSON.parse(names).map(name => {
                    return this.default_portfolio_columns.find(column => column.name == name)
                })
            }

            return this.default_portfolio_columns
        }
    },
    actions: {
        setPortfolioColumns(columns) {
            localStorage.setItem('portfolio_columns', JSON.stringify(columns.map(column => column.name)))
            this.default_portfolio_columns = columns
        }
    }
})