<template lang="pug">
v-app-bar(
    app
    color="blue-grey"
    elevation="4"
    dark
)
    v-app-bar-nav-icon(@click="$emit('openDrawer')")
    v-spacer
    v-icon.mr-3(color="blue-grey lighten-4") mdi-cash-multiple
    v-toolbar-title MyStockPortfolio
    v-spacer
    v-btn(icon @click="toggleSearch")
        v-icon mdi-magnify
    template(v-if="searchOpen" v-slot:extension)
        v-container.mt-4(fluid)
            .d-flex
                v-text-field(
                    v-model="search"
                    label="Search"
                    dense
                    outlined
                )
                v-btn(icon @click="toggleSearch")
                    v-icon mdi-close
</template>

<script>

export default {
    name: 'AppBar',
    methods: {
        toggleSearch() {
            alert('Not yet')
            // if (!this.searchOpen) {
            //     this.search = ''
            // }
            // this.searchOpen = !this.searchOpen
        },
    },
    data() {
        return {
            search: '',
            searchOpen: false,
        }
    },
}

</script>
