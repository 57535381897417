import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify'
import { createPinia, PiniaVuePlugin } from 'pinia'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(PiniaVuePlugin)

const pinia = createPinia()

new Vue({
  router,
  vuetify,
  pinia,
  render: h => h(App)
}).$mount('#app')
